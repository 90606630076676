.dot {
    animation: blink 1.5s infinite step-start;
    font-size: 16px;
    color: #000;
    font-weight: bold;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(4) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  